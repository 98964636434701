import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
import moment from "moment";
export default {
    state: {
        qnaStatus: null,
        qnaKeyword: '',
        wrongAnswerCount: 0,
        court: '',
        faqCategory: '',
        isThinking: false,
        requestQna: true
    },
    getters: {
        getQnaStatus: state => state.qnaStatus,
        getQnaKeyword: state => state.qnaKeyword,
        getWrongAnsCount: state => state.wrongAnswerCount,
        getCourt: state => state.court,
        getFaqCategory: state => state.faqCategory,
        getIsThinking: state => state.isThinking,
        getRequestQna: state => state.requestQna
    },
    mutations: {
        setQnaStatus(state, status) {
            state.qnaStatus = status;
        },
        setQnaKeyword(state, keyword) {
            state.qnaKeyword = keyword
        },
        setWrongAnswerCount(state, count) {
            state.wrongAnswerCount = count
        },
        setCourt(state, court) {
            state.court = court
        },
        setFaqCategory(state, category) {
            state.faqCategory = category
        },
        setIsThinking(state, status) {
            state.isThinking = status
        },
        setRequestQna(state, status) {
            state.requestQna = status
        }
    },
    actions: {
        pullAllQnA({ commit }) {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                //GET {Endpoint}/language/query-knowledgebases/projects/{projectName}/qnas?api-version=2021-10-01
                url: 'https://ars-studio-en.cognitiveservices.azure.com/language/query-knowledgebases/projects/' + i18n.t('qnaEndpoint').kbName + '/qnas?&api-version=2021-10-01&deploymentName=production',
                headers: {
                    'Ocp-Apim-Subscription-Key': 'da55d74bdec54b7b856aef2a8e8bbded',
                    'Content-Type': 'application/json'
                },
            };

            axios.request(config).then(response => {
                console.log('Fetching all QNAS: ', response)
            })
        },
        // gptIntegration({ dispatch, commit }, transcript) {
        //     commit("setIsThinking", true)
        //     commit("setTextBubbleText", "Thinking....")
        //     const doc = nlp(transcript);
        //     const nouns = doc.nouns().out('array');
        //     const verbs = doc.verbs().out('array');

        //     if (nouns.length === 0 || verbs.length === 0) {
        //         return transcript // cannot normalize
        //     }

        //     const subject = nouns[0];
        //     const action = verbs[0];

        //     let questionType = 'Where';
        //     if (doc.has('(who|what|where|why|how)')) {
        //         questionType = doc.match('(who|what|where|why|how)').out('normal');
        //     }

        //     let standaloneQuestion = `${questionType} can I go ${action} ${subject}?`;
        //     console.log('New generated Question: ', standaloneQuestion)
        //     dispatch('avatarSpeak', standaloneQuestion);
        //     //return standaloneQuestion;
        // },
        requestQnA({ getters, dispatch, commit }, transcript) {
            //commit("setIsThinking", true)
            if (getters.isThinking) {

                commit("setSpeechTranscript", transcript);
                commit("setTextBubbleText", transcript);
            }
            console.log('Value of is thinking: ', getters.getIsThinking)
            

            // Request to QNA
            let data = JSON.stringify({
                "top": 3,
                "question": transcript,
                "includeUnstructuredSources": true,
                "confidenceScoreThreshold": "0.2",
                "filters": {
                    "metadataFilter": {
                        "metadata": [
                            {
                                "key": "scope",
                                "value": "common"
                            },
                            {
                                "key": "scope",
                                "value": getters.getKioskLocation.toLowerCase()
                            }
                        ],
                        "logicalOperation": "OR"
                    }
                }
            });
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: i18n.t('qnaEndpoint').kbUrl,
                headers: {
                    'Ocp-Apim-Subscription-Key': i18n.t('qnaEndpoint').kbCode,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            commit("setIsThinking", true)
            commit("setTextBubbleText", "Thinking....")
            axios.request(config)
                .then((response) => {

                    commit('setTouchRequest', {
                        module: 'QnA',
                        action: transcript,
                        response: response.data.answers[0].answer,
                        timeStamp: new Date(),
                        requestType: 'Speech'
                    })
                    //dispatch('sendQnaData', qnaAnalytics);

                    if (response.data.answers[0].confidenceScore > 0.6) {
                        const qnaAnalytics = {
                            timeStamp: new Date(),
                            kioskID: getters.getKioskId,
                            transcript: transcript,
                            result: response.data,
                        }
                        dispatch('addQnaDatatoStrapi', qnaAnalytics)
                        dispatch('generateAnswer', response.data.answers[0])
                    } else if (response.data.answers[0].confidenceScore > 0.45) {
                        const qnaAnalytics = {
                            timeStamp: new Date(),
                            kioskID: getters.getKioskId,
                            transcript: transcript,
                            result: response.data,
                        }
                        dispatch('addQnaDatatoStrapi', qnaAnalytics)
                        commit("setIsThinking", false)
                        if (getters.getCurrentLanguage === 'en') {
                            dispatch('avatarSpeak', 'I didn\'t quite get that, did you mean to ask following questions. Please tap your preference.')
                        } else {
                            dispatch('avatarSpeak', 'No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia.')
                        }
                        let followUp = [];
                        response.data.answers.forEach(output => {
                            if (output.confidenceScore > 0.45)
                                console.log('Follow up question when low on confidence: ', output.questions)
                            console.log('Response Data at followup? : ', output)
                            if (output.source === "Editorial") {
                                followUp.push(output.questions[0])
                            }
                        });
                        var headerVal;
                        if (getters.getCurrentLanguage === 'en') {
                            headerVal = 'Please Tap the option you prefer'
                        } else {
                            headerVal = 'Por favor, toque la opción que prefiera'
                        }
                        dispatch('openOptionsViewer', {
                            dialogHeader: headerVal,
                            dialogData: followUp,
                        })
                    } else {
                        commit("setIsThinking", false)
                        if (getters.getKioskLocation.toLowerCase() === 'howardcc') {
                            let juryKeyword = ['jury', 'juror', 'jurors', 'jurado', 'jurada']
                            const pattern = new RegExp(`\\b(${juryKeyword.join('|')})\\b`, 'gi');
                            if (transcript.match(pattern)) {
                                    // If the input transcript has words related to jury are handled separately.
                                dispatch('juryQuestionHandler', transcript)
                            } else if (transcript.toLowerCase().indexOf('deed') >= 0 || transcript.toLowerCase().indexOf('lien') >= 0
                                || transcript.toLowerCase().indexOf('deeds') >= 0 || transcript.toLowerCase().indexOf('recordings') >= 0 || transcript.toLowerCase().indexOf('records') >= 0) {
                                mapPath = getters.getMaps.get('clerk of the court')
                                floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                                dispatch('openImageViewer', {
                                    name: mapPath.mapName,
                                    url: mapPath.mapImage,
                                    floor: floorName[0].floorName,
                                    showQR: false,
                                    qrUrl: ""
                                })
                                dispatch('avatarSpeak', 'The Land Records Office of the Clerk of the Circuit Court handles deed, Lien, and Land records matters. The Clerk of the Court office is on the same floor, circled in red.')
                                const qnaAnalytics = {
                                    timeStamp: new Date(),
                                    kioskID: getters.getKioskId,
                                    transcript: transcript,
                                    result: {
                                        "answers": [
                                            {
                                                "questions": [
                                                    transcript,
                                                    "what is the process for submitting land record documents for recording",
                                                    "Record a deed"
                                                ],
                                                "answer": "The Land Records Office of the Clerk of the Circuit Court handles deed, Lien, and Land records matters. The Clerk of the Court office is on the same floor, circled in red.",
                                                "confidenceScore": 1
                                            }
                                        ]
                                    },
                                }
                                dispatch('addQnaDatatoStrapi', qnaAnalytics)
                            } else {
                                const qnaAnalytics = {
                                    timeStamp: new Date(),
                                    kioskID: getters.getKioskId,
                                    transcript: transcript,
                                    result: response.data,
                                }
                                dispatch('addQnaDatatoStrapi', qnaAnalytics)
                                if (getters.getKioskProfile.data().telepresence) {
                                    commit('setTelepresenceViewer', true)
                                } else {
                                    var mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                                    var floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                                    dispatch('openImageViewer', {
                                        name: mapPath.mapName,
                                        url: mapPath.mapImage,
                                        floor: floorName[0].floorName,
                                        showQR: false,
                                        qrUrl: ""
                                    })
                                    dispatch('avatarSpeak', i18n.t('kbError'))
                                }

                            }
                        } else {
                            const qnaAnalytics = {
                                timeStamp: new Date(),
                                kioskID: getters.getKioskId,
                                transcript: transcript,
                                result: response.data,
                            }
                            dispatch('addQnaDatatoStrapi', qnaAnalytics)
                            if (getters.getKioskProfile.data().telepresence) {
                                commit('setTelepresenceViewer', true)
                            } else {
                                mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                                floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                                dispatch('openImageViewer', {
                                    name: mapPath.mapName,
                                    url: mapPath.mapImage,
                                    floor: floorName[0].floorName,
                                    showQR: false,
                                    qrUrl: ""
                                })
                                dispatch('avatarSpeak', i18n.t('defaultKBError'))
                            }
                        }
                    }
                })
                .catch((error) => {
                    commit("setIsThinking", false)
                    console.log(error);
                });
        },
        generateAnswer({ dispatch, commit, getters }, result) {
            console.log('Value of is thinking 2:  ', getters.getIsThinking)
            //setTimeout(() => {  }, 250)
            if (getters.getIsThinking) {
                router.push('/')
                commit('setQnaStatus', false)
                const metadata = result.metadata.intent
                const ansObj = result
                commit('setDefaultBubbleText', false)
                commit('closeOptionsViewer')
                switch (metadata) {
                    case "navigate":
                        var menuName = ansObj.metadata.navigateto
                        router.addRoute({ path: menuName.toLowerCase(), component: () => import('@/views/' + menuName) });
                        if (router.currentRoute.path !== menuName) {
                            router.push(menuName).then(() => {
                                commit('setQnaKeyword', '')
                                dispatch('avatarSpeak', ansObj.answer);
                                let page = i18n.t('homepages').filter(page => page.pageType[0].pathName === menuName)
                                commit('setPageHeader', page[0].pageType[0].pageHeader)
                            })
                        } else {
                            commit('setQnaKeyword', '')
                            dispatch('avatarSpeak', ansObj.answer)
                        }
                        break;

                    case "showmap":
                        try {
                            var mapPath = i18n.t('maps').filter(map => map.displayPosition.toString() === ansObj.metadata.mapposition)
                            //window.loadText(mapPath[0].speech, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                            var floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath[0].mapFloor)
                            if (ansObj.answer.trim() !== 'default') {
                                dispatch('avatarSpeak', ansObj.answer + ' ' + mapPath[0].speech);
                            } else {
                                dispatch('avatarSpeak', mapPath[0].speech);
                            }
                            dispatch('openImageViewer', {
                                name: mapPath[0].mapName,
                                url: mapPath[0].mapImage,
                                floor: floorName[0].floorName,
                                showQR: false,
                                qrUrl: ""
                            })


                        } catch (e) {
                            mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                            floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                            dispatch('avatarSpeak', i18n.t('mapNotFound'))
                            dispatch('openImageViewer', {
                                name: mapPath.mapName,
                                url: mapPath.mapImage,
                                floor: floorName[0].floorName,
                                showQR: false,
                                qrUrl: ""
                            })

                        }

                        break;

                    case "speak":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        dispatch('avatarSpeak', ansObj.answer);
                        break;
                    case "showmapqr":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        mapPath = i18n.t('maps').filter(map => map.displayPosition.toString() === ansObj.metadata.mapposition)
                        floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath[0].mapFloor)
                        var qrurl = ansObj.metadata.url
                        dispatch('avatarSpeak', ansObj.answer);
                        dispatch('openImageViewer', {
                            name: mapPath[0].mapName,
                            url: mapPath[0].mapImage,
                            floor: floorName[0].floorName,
                            showQR: true,
                            qrUrl: 'https://' + qrurl
                        })


                        break;
                    case "showform":
                        try {
                            var formName = ansObj.metadata.find(({ name }) => name === 'formname')
                            getters.getFormsEnglish.forEach(form => {
                                if (form.formName.toLowerCase() === formName.value) {
                                    dispatch('openPdfViewer', {
                                        url: form.formPdf,
                                        name: form.formName,
                                        desc: '',
                                        emailLink: form.pdfUrl,
                                        type: 'Form'
                                    })
                                    dispatch('avatarSpeak', ansObj.answer);
                                }
                            })
                        } catch (e) {
                            mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                            floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                            dispatch('openImageViewer', {
                                name: mapPath.mapName,
                                url: mapPath.mapImage,
                                floor: floorName[0].floorName,
                                showQR: false,
                                qrUrl: ""
                            })
                            dispatch('avatarSpeak', i18n.t('formNotFound'))
                        }


                        break;
                    case "searchbykeyword":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        commit("setQnaKeyword", "")
                        router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                        if (router.currentRoute.path !== '/faqs') {
                            router.push('/faqs')
                        }
                        var page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
                        commit('setPageHeader', page[0].pageType[0].pageHeader)
                        dispatch('avatarSpeak', ansObj.answer);
                        var linkId = ansObj.metadata.linkid
                        commit("setQnaKeyword", linkId)

                        break;

                    case "showfaqcategory":
                        var faqKeyword = ansObj.metadata.keyword

                        router.addRoute({ path: '/faqs', component: () => import('@/views/faqs') })
                        if (router.currentRoute.path !== '/faqs') {
                            router.push('/faqs')
                        }
                        page = i18n.t('homepages').filter(page => page.pageType[0].pathName === 'faqs')
                        commit('setPageHeader', page[0].pageType[0].pageHeader)

                        commit('setSelectedFaqCat', { name: faqKeyword, type: 'expansionPanel' })
                        dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showformcategory":

                        var formKeyword = ansObj.metadata.keyword
                        router.addRoute({ path: '/forms', component: () => import('@/views/forms') })
                        if (router.currentRoute.path !== '/forms') {
                            router.push('/forms')
                        }
                        commit('setPageHeader', 'Forms')
                        commit('setQnaKeyword', formKeyword)
                        dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showformpackets":

                        var packetInfo = getters.getFormPacketsEnglish.get(ansObj.metadata.find(({ name }) => name === 'keyword').value)
                        dispatch('openPdfViewer', {
                            url: packetInfo.packetFiles,
                            name: packetInfo.packetName,
                            desc: packetInfo.packetDesc,
                            emailLink: packetInfo.packetLink,
                            type: 'Packet'
                        })
                        //dispatch('avatarSpeak', ansObj.answer);
                        break;

                    case "showemail":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        var url = ansObj.metadata.url
                        var docName = ansObj.metadata.name
                        dispatch('openEmailViewer', {
                            pdfType: 'link',
                            pdfName: docName,
                            pdfUrl: "https://" + url
                        })
                        dispatch('avatarSpeak', ansObj.answer)
                        break;
                    case "showdate":

                        var date = new Date()
                        console.log('Date', moment(date).format('LLLL'))
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        dispatch('avatarSpeak', "Today's date is " + moment(date).format('dddd') + ' ' + moment(date).format('LL'));
                        break;

                    case "showtime":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        dispatch('avatarSpeak', "It is " + moment(date).format('LT'));
                        break;
                    case "followup":
                        //window.loadText(ansObj.answer, getters.getAvatarLangCodes.voiceId, getters.getAvatarLangCodes.langId, getters.getAvatarLangCodes.engineId)
                        var prompts = ansObj.dialog.prompts
                        var followUp = [];
                        dispatch('avatarSpeak', ansObj.answer);
                        console.log('Follow up question when metadata is followup: ', prompts)
                        prompts.forEach(item => followUp.push(item.displayText));
                        dispatch('openOptionsViewer', {
                            dialogHeader: i18n.t('selectOptionMsg'),
                            dialogData: followUp,
                        })
                        break;
                    default:
                        mapPath = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                        floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
                        dispatch('openImageViewer', {
                            name: mapPath.mapName,
                            url: mapPath.mapImage,
                            floor: floorName[0].floorName,
                            showQR: false,
                            qrUrl: ""
                        })
                        dispatch('avatarSpeak', i18n.t('kbError'))
                        break;
                }
            }
        },

        juryQuestionHandler({ getters, dispatch }, transcript) {
            let mapPath = getters.getMaps.get('jury assembly')
            let floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
            dispatch('openImageViewer', {
                name: mapPath.mapName,
                url: mapPath.mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: ""
            })
            if (getters.getCurrentLanguage === 'en') {
                dispatch('avatarSpeak', 'The Jury Assembly room is on the second floor, circled in red. Please use the elevators or stairs to get there. Jury staff can answer questions related to jury service.')
                const qnaAnalytics = {
                    timeStamp: new Date(),
                    kioskID: getters.getKioskId,
                    transcript: transcript,
                    result: {
                        "answers": [
                            {
                                "questions": [
                                    transcript,
                                    "Where is the jury assembly room",
                                    "I am here for jury duty"
                                ],
                                "answer": "The Jury Assembly room is on the second floor, circled in red. Please use the elevators or stairs to get there. Jury staff can answer questions related to jury service.",
                                "confidenceScore": 1
                            }
                        ]
                    },
                }
                dispatch('addQnaDatatoStrapi', qnaAnalytics)
            } else {
                dispatch('avatarSpeak', 'La sala Jury Assembly está en el segundo piso, rodeado de rojo. Por favor, utilice los ascensores o escaleras para llegar allí. El personal del jurado puede responder preguntas relacionadas con el servicio del jurado.')
                const qnaAnalytics = {
                    timeStamp: new Date(),
                    kioskID: getters.getKioskId,
                    transcript: transcript,
                    result: {
                        "answers": [
                            {
                                "questions": [
                                    transcript,
                                    "¿Dónde está la sala de reuniones del jurado",
                                    "Estoy aquí por el deber del jurado"
                                ],
                                "answer": "La sala Jury Assembly está en el segundo piso, rodeado de rojo. Por favor, utilice los ascensores o escaleras para llegar allí. El personal del jurado puede responder preguntas relacionadas con el servicio del jurado.",
                                "confidenceScore": 1
                            }
                        ]
                    },
                }
            }

        }

    }
}

import axios from 'axios'

const fs = require('fs')
import i18n from "../../i18n";
export default {
    state: {
        hearingInfo: [],
        lastFetched: null,
        intervalTimer: 300000,
        hearingLocation: {
            '01': 'Courtroom 1',
            '02': 'Courtroom 2',
            '03': 'Courtroom 3',
            '04': 'Courtroom 4',
            '05': 'Courtroom 5',
            '06': 'Courtroom 6',
            '07': 'Courtroom 7',
            '08': 'Courtroom 8',
            'Courtroom 1': 'Courtroom 1',
            'Courtroom 2': 'Courtroom 2',
            'Courtroom 3': 'Courtroom 3',
            'Courtroom 4': 'Courtroom 4',
            'Courtroom 5': 'Courtroom 5',
            'Courtroom 6': 'Courtroom 6',
            'Courtroom 7': 'Courtroom 7',
            'Courtroom 8': 'Courtroom 8',
            'Courtroom 9': 'Courtroom 9',
            'Courtroom 10': 'Courtroom 10',
            'Courtroom 11': 'Courtroom 11',
            'Courtroom 12': 'Courtroom 12',
            'Settlement Conference Room 3041': 'Settlement Conference Room 3041',
            'Settlement Conference Room 3500': 'Settlement Conference Room 3500',
            'RE': 'Remote',
            'C1': 'Room C1',
            'C3': 'Room C3',
        }
    },
    getters: {
        hearingInfo: state => state.hearingInfo,
        lastFetched: state => state.lastFetched,
        getHearingLocation: state => state.hearingLocation,
        intervalTimer: state => state.intervalTimer
    },
    mutations: {
        setHearingInfo(state, hearingObj) {
            //console.log('Hearing Obj', hearingObj)
            //state.hearingInfo.push(hearingObj)
            state.hearingInfo = hearingObj
        },
        emptyHearingInfo(state) {
            state.hearingInfo = []
        },
        setLastFetched(state, fetchObj) {
            state.lastFetched = fetchObj
        },
        setIntervalTimer(state, timer) {
            state.intervalTimer = timer
        }
    },
    actions: {
        verifyFile({ dispatch, getters, commit }) {
            return new Promise((resolve, reject) => {
                var manualId = 0;
                const config = {
                    method: 'post',
                    url: 'https://us-central1-maryland-eb75e.cloudfunctions.net/apiV2/getHearingDetails/a763cd056f1b2405788443b7197e0708',
                    params: { filename: getters.getKioskProfile.data().hearingFile + '.txt' }
                }

                axios(config)
                    .then((response) => {
                        let hearingObj; let temp = []; var floorName = ''
                        commit("setLastFetched", response.data.Header.Time)
                        response.data.Data.forEach(hearing => {
                            hearingObj = {
                                id: manualId++,
                                caseNumber: hearing.CaseNumber,
                                hearingTime: hearing.HearingTime,
                                partyName: hearing.PartyName.replace(/\//g, ' * '),
                            }
                            console.log('Default location: ', getters.getDefaultLocation)
                            if (hearing.HearingLocation === undefined) {
                                if (hearingObj.hearingLocation === undefined) {
                                    hearingObj['hearingLocation'] = getters.getDefaultLocation
                                    let noHearingLocation = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                                    floorName = i18n.t('floors').filter(floor => floor.floorNumber === noHearingLocation.mapFloor)
                                    hearingObj['floorName'] = floorName[0].floorName
                                }
                            } else {
                                i18n.t("maps").forEach(mapper => {
                                    if (mapper.hearingLocationCode !== null) {
                                        if (mapper.hearingLocationCode.toLowerCase().trim() === hearing.HearingLocation.toLowerCase().trim()) {
                                            floorName = i18n.t('floors').filter(floor => floor.floorNumber === mapper.mapFloor)
                                            hearingObj['hearingLocation'] = mapper.mapName
                                            hearingObj['floorName'] = floorName[0].floorName
                                        }
                                    }
                                })
                                if (hearingObj.hearingLocation === undefined) {
                                    hearingObj['hearingLocation'] = getters.getDefaultLocation // Clerk's Office 1
                                    let noHearingLocation = getters.getMaps.get(getters.getDefaultLocation.toLowerCase())
                                    floorName = i18n.t('floors').filter(floor => floor.floorNumber === noHearingLocation.mapFloor)
                                    hearingObj['floorName'] = floorName[0].floorName
                                }
                            }
                            temp.push(hearingObj)
                            //commit('setHearingInfo', hearingObj)
                        })
                        commit('setHearingInfo', temp)
                        resolve(true)
                    })
                    .catch((error) => {
                        resolve(false)
                        if (error.response.status === 404) {
                            console.log('File not present on FTP server')
                        } else if (error.response.status === 403) {
                            console.log('Access problem to FTP Server')
                        }
                    });
            })

        },
        filePullScheduler({ dispatch, getters }) {
            const currentDate = new Date()
            const hour = currentDate.getHours()
            // 7 AM to 6 PM 
            // if (hour === 11) {
            //     location.reload()
            // }

            if (hour >= 7 && hour < 17) {
                dispatch('verifyFile')
                setTimeout(() => { dispatch('filePullScheduler') }, 300000)
            } else if (hour >= 18) {
                dispatch('verifyFile')
                setTimeout(() => {
                    dispatch('filePullScheduler')
                }, 3600000)
            } else {
                dispatch('verifyFile')
                setTimeout(() => {
                    dispatch('filePullScheduler')
                }, 3600000)
            }
        },

    }
}

<template>
  <v-row justify="center" align="start" class="fill-height">
    <v-col class="pt-10" cols="12" align="center">
      <span class="text-h2 font-weight-bold grey--text text--darken-3">
        {{ $t('default') }}
      </span>
    </v-col>
    <v-col cols="10" class="py-2">
      <v-row class="fill-height mt-6 mb-6" align="center" justify="center">
        <v-col align="center" :cols="page.colLength" v-for="(page, index) in $t('homepages')" :key="index" class="mb-3">
          <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center"
            v-if="page.colLength === 5" :height="menu.height" elevation="5" rounded>
            <v-img :src="page.displayIcon.url" :width="menu.imageWidth" contain>
            </v-img>
            <v-card-text class="text-h5 black--text font-weight-bold">
              {{ page.pageName }}
            </v-card-text>
          </v-card>
          <v-card @click="goto(page)" class="menuItem d-flex flex-column align-center justify-center" v-else
            :height="menu.height" elevation="5" rounded>
            <v-row no-gutters class="fill-height">
              <v-col cols="5" align-self="center">
                <v-img :src="page.displayIcon.url" width="75%" contain class="ml-5"></v-img>
              </v-col>
              <v-col cols="7" align-self="center">
                <v-card-text class="text-h5 black--text font-weight-bold">
                  {{ page.pageName }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider class="pb-4 mx-10"></v-divider>
      <languageBar></languageBar>
    </v-col>
  </v-row>
</template>

<script>
import languageBar from '@/components/languageBar'
import { mapGetters } from 'vuex'

export default {
  name: 'menuMatrix',
  components: {
    languageBar
  },
  data() {
    return {
      menu: {
        height: null,
        width: null,
        col: null,
        imageWidth: null
      }
    }
  },
  computed: {
    ...mapGetters(['getCMSlink', 'getTouchCount', 'isUserLooking', 'getAvatarLangCodes', 'getMaps', 'getKioskProfile'])
  },
  watch: {
    getKioskProfile(newVal) {
      if (newVal.data().modules.length !== this.length) {
        this.length = newVal.data().modules.length
        console.log('Coming in here')
        this.$store.dispatch('indentifyModuleLang', {
          module: this.getKioskProfile.data().modules,
          language: this.getKioskProfile.data().languages
        }).then(() => {
          this.$store.dispatch("createApolloConnection", this.getKioskProfile.data().cmsLink + '/graphql').then(response => {
            this.$store.dispatch("fetchHomePage")
          })
        })
      }
    }
  },
  methods: {
    goto(page) {
      if (page.pageType.length !== 0) {
        switch (page.pageType[0].__typename) {
          case 'ComponentHomepageNavigation':
            this.$store.commit('setRequestQna', false)
            this.$store.commit("setIsThinking", false)
            this.$store.commit("setSpeechRecognitionHandler", false);
            this.$router.push('/' + page.pageType[0].pathName.toLowerCase());
            this.$store.commit('setPageHeader', page.pageType[0].pageHeader)
            this.$store.commit('setQnaKeyword', '')

            // To start session using touch - when no face detected
            if (!this.isUserLooking) {
              this.$store.commit('setTouchCount', this.getTouchCount + 1)
              if (this.getTouchCount === 1) {
                // start time
                this.$store.commit('setStartUserSession', new Date())

                //session start object
                this.$store.commit('setTouchRequest', {
                  module: 'Default',
                  action: 'Touch Detected',
                  response: 'User Session Started - Touch Detected',
                  timeStamp: new Date(),
                  requestType: 'Session'
                })
              }
            }

            // Will be called irrespective of Touch Count
            this.$store.commit('setTouchRequest', {
              module: 'Home Page',
              action: 'navigate',
              response: page.pageType[0].pathName,
              timeStamp: new Date(),
              requestType: 'Touch'
            })
            this.$store.commit('setDefaultBubbleText', true)
            this.$store.dispatch('avatarSpeak', page.speech)
            break;
          case 'ComponentHomepageDialogBox':
            console.log('How to display Dialog: ', page.pageType[0])
            if (page.pageType[0].type === 'map') {
              let mapPath = this.getMaps.get(page.pageType[0].mapName)
              let floorName = this.$i18n.t('floors').filter(floor => floor.floorNumber === mapPath.mapFloor)
              this.$store.dispatch('avatarSpeak', page.speech)
              this.$store.dispatch('openImageViewer', {
                name: mapPath.mapName,
                url: mapPath.mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: ""
              })
              this.$store.commit('setTouchRequest', {
                module: 'Home Page',
                action: 'showmap',
                response: page.pageType[0].textList,
                timeStamp: new Date(),
                requestType: 'Touch'
              })
            }
            break;
          default:
            break;
        }
      }

    },
  },
  mounted() {
    if (this.$i18n.t('homepages').length <= 4) {
      this.menu = {
        height: 270,
        width: 285,
        col: 5,
        imageWidth: "60%"
      }
    } else if (this.$i18n.t('homepages').length <= 6) {
      this.menu = {
        height: 260,
        width: 275,
        col: 4,
        imageWidth: "55%"
      }
    }
  }
}
</script>

<style>
.menuItem {
  border: solid 3px #2c6b87 !important;
}

.footer {
  bottom: 0 !important;
}
</style>
<i18n>
    {
      "en": {
          "default": "How Can I Help You?"
        },
      "es":{
          "default": "¿Cómo puedo ayudarlo?"
        }
    }
  </i18n>